import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
    })
    // Align style of slim to Daisy
    const main = document.getElementsByClassName('ss-main')[0]
    main.classList.add('select', 'select-bordered', 'text-neutral-focus')
    main.style.width = '700px'
    const arrow = document.getElementsByClassName('ss-arrow')[0]
    arrow.remove()
  }
}
