import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'projectSearchInput'
  ]

  connect() {
    this.setSearchParamsFromUrl()
  }

  search(e) {
    // keyCode 13 is Enter
    if (e.code === 'Enter') {
      e.preventDefault()
      this.searchRequest(this.projectSearchInputTarget.value)
    }
  }

  clearSearch() {
    if (
      this.projectSearchInputTarget.value === '' ||
      this.projectSearchInputTarget.value === null
    ) {
      this.searchRequest('') 
    }
  }

  searchRequest(keywords) {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams
    searchParams.set('projects_grid[name]', keywords)

    url.search = searchParams.toString()
    window.location = url.toString()
  }

  setSearchParamsFromUrl() {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams

    if (searchParams.get('projects_grid[name]')) {
      this.projectSearchInputTarget.value = searchParams.get(
        'projects_grid[name]'
      )
    }
  }
}