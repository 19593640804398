import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'projectTypeSelect', 
    'projectSelect',
    'searchInferredAtErrorMessage',
    'searchInferredStartAt',
    'searchInferredEndAt',
    'searchAddress',
    'searchInferrer',
    'searchPolicyNumber',
    'searchQuoteNumber',
    'collapsionBtn'
  ]

  connect() {
    this.setSearchParamsFromUrl()
    this.toggleSearchBar()
  }

  submit() {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams

    searchParams.set('project_type_id', this.projectTypeSelectTarget.value)
    searchParams.set('project_id', this.projectSelectTarget.value)

    url.search = searchParams.toString()
    window.location = url.toString()
  }

  search() {
    // Validation
    this.toggleInferredAtErrorMessage()
    if (!this.inferredAtIsValid()) return

    let url = new URL(window.location.href)
    let searchParams = url.searchParams

    searchParams.set(
      'inference_results_grid[inferenced_at]',
      [this.searchInferredStartAtTarget.value, this.searchInferredEndAtTarget.value]
    )
    searchParams.set(
      'inference_results_grid[address]',
      this.searchAddressTarget.value
    )
    searchParams.set(
      'inference_results_grid[inferrer]',
      this.searchInferrerTarget.value
    )
    searchParams.set(
      'inference_results_grid[policy_number]',
      this.searchPolicyNumberTarget.value
    )
    searchParams.set(
      'inference_results_grid[quote_number]',
      this.searchQuoteNumberTarget.value
    )

    url.search = searchParams.toString()
    window.location = url.toString()
  }

  setSearchParamsFromUrl() {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams
    // Set up params in the url
    if (searchParams.get('inference_results_grid[inferenced_at]')) {
      const dates = searchParams
        .get('inference_results_grid[inferenced_at]')
        .split(',')
      this.searchInferredStartAtTarget.value = dates[0]
      this.searchInferredEndAtTarget.value = dates[1]
    }

    if (searchParams.get('inference_results_grid[address]')) {
      this.searchAddressTarget.value = searchParams.get(
        'inference_results_grid[address]'
      )
    }

    if (searchParams.get('inference_results_grid[inferrer]')) {
      this.searchInferrerTarget.value = searchParams.get(
        'inference_results_grid[inferrer]'
      )
    }

    if (searchParams.get('inference_results_grid[policy_number]')) {
      this.searchPolicyNumberTarget.value = searchParams.get(
        'inference_results_grid[policy_number]'
      )
    }

    if (searchParams.get('inference_results_grid[quote_number]')) {
      this.searchQuoteNumberTarget.value = searchParams.get(
        'inference_results_grid[quote_number]'
      )
    }
  }

  toggleSearchBar() {
    let url = new URL(window.location.href)
    let searchParams = url.searchParams
    // Open the search bar when any searchParams exist
    if (
      searchParams.get('inference_results_grid[inferenced_at]') ||
      searchParams.get('inference_results_grid[address]') ||
      searchParams.get('inference_results_grid[inferrer]') ||
      searchParams.get('inference_results_grid[policy_number]') ||
      searchParams.get('inference_results_grid[quote_number]')
    ) {
      this.collapsionBtnTarget.checked = 'checked'
    } else {
      this.collapsionBtnTarget.checked = ''
    }
  }

  downloadCsv() {
    let url = new URL(window.location.href)
    url.pathname = url.pathname + '/download_csv'
    window.location = url.toString()
  }

  inferredAtIsValid() {
    if (
      !!this.searchInferredStartAtTarget.value &&
      !!this.searchInferredEndAtTarget.value
    ) {
      return (
        this.searchInferredStartAtTarget.value <=
        this.searchInferredEndAtTarget.value
      )
    }

    return true
  }

  toggleInferredAtErrorMessage() {
    if (!this.inferredAtIsValid()) {
      this.searchInferredEndAtTarget.classList.add("input-error")
      this.searchInferredStartAtTarget.classList.add("input-error")
      this.searchInferredAtErrorMessageTarget.style.display = 'block'
    } else {
      this.searchInferredEndAtTarget.classList.remove("input-error")
      this.searchInferredStartAtTarget.classList.remove("input-error")
      this.searchInferredAtErrorMessageTarget.style.display = 'none'
    }
  }
}
